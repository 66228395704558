// Start custom code.
// God help us.

.m-login.m-login--1 .m-login__wrapper {
    padding-top: 2rem;
}

.m-login {
    display:flex;
}

.m-t-1 {
    margin-top: 1rem;
}

.m-scroll-top>i {
    padding-top: .2rem;
    display: inline-block;
}

.m-btn--logout {
    display: none;
}

.form-control, .form-control[readonly] {
    border-color: #ccc;
}

.has-danger .form-control, .has-danger .input-group-text {
    border-color: red;
}

@media (min-width: 993px) {
    .m-grid.m-grid--ver-desktop.m-grid--desktop>.m-grid__item.m-login__aside {
        display: flex;
        align-content: center;
        align-items: center;
        min-width: 680px;
    }

    .m-login.m-login--1 .m-login__content {
        padding: 5rem 4rem 10rem 4rem;
        width: 100%;
    }

    .m-login__aside .m-stack.m-stack--desktop, .m-login__aside .m-stack.m-stack--desktop.m-stack--hor>.m-stack__item {
        display: block;
        height: auto;
        table-layout: initial;
    }

    .m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) .m-footer {
        margin-left: 0;
    }
}

@media (max-width: 1200px) and (min-width: 993px) {
    .m-login.m-login--1 .m-login__content .m-login__welcome {
        font-size: 2.5rem;
    }
}

@media (max-width: 992px) {
    .m-topbar {
        background: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .m-t-1 {
        padding-bottom: 1rem;
    }

    .m-portlet .m-portlet__body {
        padding: 0;
    }

    .m-nav.m-nav--inline>.m-nav__item>.m-nav__link {
        padding-top: 4px;
    }

    .m-topbar .m-topbar__nav.m-nav>.m-nav__item>.m-nav__link .m-topbar__userpic {
        vertical-align: top;
    }

    .m-datatable__head tr > th:first-of-type, .m-datatable__body tr > td:first-of-type {
        padding-left: 1.4rem !important;
    }

    .m-portlet .m-portlet__head {
        height: auto;
        padding: 0 1.2rem;
        white-space: nowrap;
    }

    .m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .14rem;
        padding: 1.2rem 0;
        color: #bbb;
        margin: 0;
    }

    .m-portlet .m-portlet__head {
        border-bottom: none;
        background: #000;
    }

    .m-body .m-content {
        padding: 0 0 30px 0;
    }

    .m-btn--logout {
        display: inline-block;
        margin: 1.6rem 0;
    }

    .m-datatable.m-datatable--default>.m-datatable__pager {
        margin: 15px 20px 0 20px;
        padding-bottom: 20px;
    }
}

@media (max-width: 680px) {

    .m-portlet .m-portlet__head .m-portlet__head-caption {
        display: block;
    }

    .m-datatable.m-datatable--default>.m-datatable__pager>.m-datatable__pager-nav>li>.m-pager-input {
        -webkit-appearance: none;
    }
}

.form-control {
    font-family: "IBM Plex Sans", sans-serif;
}

.m-btn--pill {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .2rem;
}

.m-login.m-login--1 .m-login__content .m-login__msg {
    font-size: 1.3rem;
}

.m-page--fluid .m-header, .m-page--wide .m-header {
    background: #ff5200;
}

.m-brand__logo-wrapper img {
    display: block;
    width: 12rem;
}

.m-portlet__head-title .btn.btn-secondary {
    background: #999;
    border: 1px solid #999;
    font-weight: bold;
    padding: .5rem 1.2rem;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:focus,
    &.btn.btn-secondary:hover:not(:disabled) {
        background-color: #666;
        border-color: #666;
        color: #fff;
    }

    &, .la {
        color: #fff;
    }
}

[data-hide-title="true"] {
    text-indent: -9999px;
}

.m-nav .m-nav__item>.m-nav__link {
    text-align: left;
    background-color: transparent;
    border: none;
}

.m-nav .m-nav__item>.m-nav__link .m-nav__link-icon {
    width: 55px;
}

.m-nav .m-nav__item>.m-nav__link .m-nav__link-text {
    padding-left: 7px;
}

.larger-icon {
    font-size: 1.7rem !important;
}

.la-signal {
    font-size: 1.5rem !important;
}

.mCustomScrollbar[data-scrollbar-shown=true].mCS-autoHide>.mCustomScrollBox~.mCSB_scrollTools_horizontal {
    display: none !important;
}

.icon-column span {
    width: 25px !important;
}

.icon-actions span {
    width: 120px !important;
}

.btn-group--head {
    margin: 2rem 0;
    width: 100%;
}

.form-group--subfilter {
    width: 40rem;
    margin-bottom: 2rem;
}

.form-group--subfilter label {
    color: #888;
    text-transform: uppercase;
}

.custom-select--mobile {
    background: #555 url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23cccccc' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 8px 10px;
    color: #fff;
    border: 1px solid #555;
    font-weight: bold;
    padding: 0 2rem 0 1rem;
}

.m-menu__nav--custom {
    .m-menu__link {
        background: transparent;
        border: none;

        &:hover {
            background: #111;
        }

        .m-menu__link-text, .m-menu__link-icon {
            color: #fff !important;
        }

        .m-menu__link-text {
            font-weight: bold !important;
        }

        .m-menu__link-icon {
            font-size: 1.8rem !important;
        }

        &.m-menu__link--current {
            background: #ff5200 !important;
        }
    }
}

@media (min-width: 993px) {
    .m-header-menu {
        display: none !important;
    }
}

@media (max-width: 992px) {
    .m-brand__tools .m-brand__icon {
        margin-left: 0;
        margin-right: -.5rem;
        text-decoration: none !important;
        color: #fff;

        .la {
            font-size: 2.5rem;
            margin-top: -.3rem;
        }
    }

    .admin-actions {
        padding-bottom: 1rem;
    }

    .m-brand__logo {
        vertical-align: middle !important;
    }

    .m-topbar .m-topbar__nav.m-nav {
        margin: 0 70px 0 30px;
    }

    .m-aside-header-menu-mobile.m-aside-header-menu-mobile--skin-dark {
        background: #222;
    }

    .m-aside-header-menu-mobile-close.m-aside-header-menu-mobile-close--skin-dark>i {
        color: #999
    }

    .m-aside-header-menu-mobile-close.m-aside-header-menu-mobile-close--skin-dark {
        background-color: #333;
    }
}